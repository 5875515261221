<template>
  <div>
    <CRow
      class="row-list nav-contaner"
      style="justify-content: flex-start; height: 30px; margin-bottom: 20px"
    >
      <div>
        <router-link :to="`/equipment-items/`">
          <CButton color="success"> Перейти на план </CButton>
        </router-link>
      </div>
    </CRow>

    <div v-if="equipmentItem" class="container print-container mt-4">
      <div>
        <div style="width: 100%">
          <iframe
            :src="equipmentItem.installation_scheme"
            width="100%"
            height="600px"
            allow="autoplay"
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap");

html,
body {
  margin: 0;
  height: 99%;
}

.contaner-view {
  background-color: white;
}

.print-container {
  background-color: white;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
}

@media print {
  header,
  footer,
  aside,
  nav,
  form,
  iframe,
  .menu,
  .hero,
  .adslot,
  .c-header,
  .nav-contaner {
    display: none;
  }

  table,
  img,
  svg {
    break-inside: avoid;
  }
}
</style>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "InstallationScheme",
  data() {
    return {
      equipmentItem: null,
      storeSubscription: null,
    };
  },
  computed: {
    ...mapState({
      equipmentItems: (state) => state.equipmentItems.all,
    }),
  },
  created() {
    this["equipmentItems/getAll"]();
    this.storeSubscription = this.$store.subscribe((mutation) => {
      if (mutation.type === "equipmentItems/getAllSuccess") {
        for (const equipmentItem of mutation.payload) {
          if (equipmentItem.code === this.$route.params.code) {
            this.equipmentItem = equipmentItem;
          }
        }
      }
    });
  },
  beforeDestroy() {
    if (this.storeSubscription) {
      this.storeSubscription();
    }
  },
  methods: {
    ...mapActions(["equipmentItems/getAll"]),
  },
};
</script>
